.mogul-header {
    font-family: "Alegreya";
    width: max-content;
}

.mr-content {
    /* width: 800px; */
}

.tags {
    max-width: 500px;
    overflow-x: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
}
.tags::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
}

@media only screen and (max-width: 800px) {
    .content {
        /* width: 90%; */
        padding: 50px;
    }
    .mr-content {
        /* width: 90%%; */
    }
    .tags {
        max-width: 250px;
    }
}
