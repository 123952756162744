.app {
  display: flex;
  flex-flow: column;
  height: 100%;
  margin: 0;
  font-family: "DM Mono";
}

html {
  height: 100%;
  margin: 0;
}