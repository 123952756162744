.homepage {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: max-content;
}

.nostyle {
    text-decoration: none !important;
    color: #424242 !important;
}

.uno {
    margin-top: 35px;
}

.uno-left {
    display: flex;
    flex-direction: column;
}

.connect:hover {
    background-color: theme("colors.sky.600");
    color: white;
}

.navlink {
    margin: 15px;
    cursor: pointer;
}

.content {
    flex-grow: 1;
}


.definition-header {
    font-size: 22px;
    font-weight: bold;
}

.definition-container {
    margin: 25px 0px;
    display: flex;
    justify-content: space-between;
}

.dos {
    background-color: #e7e3dc;
    flex-direction: column;
}

.note {
    margin: 15px 0px;
}

.blurb {
    outline: 1px solid theme("colors.sky.600");
    padding: 15px;
    border-radius: 5px;
}

.examples-wrapper {
    display: flex;
    justify-content: center;
}

.examples {
    width: max-content;
}

.content-left {
    max-width: 45%;
}
.content-right {
    max-width: 50%;
}

.stonemasters-wrap {
    width: max-content;
    padding: 15px;
    margin-bottom: 25px;
    border-radius: 10px;
    outline: 5px dashed theme("colors.amber.500");
}

.stonemasters {
    max-width: 350px;
    border-radius: 5px;
}

.content-header {
    color: theme("colors.amber.500");
    font-weight: 1000;
    font-size: 30px;
    text-overflow: wrap;
}

.header-block {
    margin-bottom: 15px;
}

.content-subheader {
    color: theme("colors.amber.500");
    font-weight: 1000;
    font-size: 20px;
    text-overflow: wrap;
}

.highlight {
    background-color: theme("colors.amber.500");
    color: white;
    border-radius: 3px;
    padding: 0px 5px;
}

.definition {
    color: #424242;
}

.definition-word {
    color: theme("colors.amber.500");
    text-align: left;
    font-size: 40px;
    font-family: "Alegreya";
}

.content-block {
    margin: 15px 0px;
}

.emphasis {
    font-weight: 1000;
    color: theme("colors.amber.500");
}

.built {
    padding-inline-start: 2ch;
    list-style-type: "🤙 ";
}

.tres {
    flex-direction: column;
    align-items: center;
    margin: 30px 0px;
}

.community-grid {
    max-width: 1000px;
    border-radius: 10px;
    outline: 5px dashed theme("colors.amber.500");
    padding: 30px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.community-text {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.community-header {
    color: theme("colors.amber.500");
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 10px;
}

.community-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.community-img-wrap {
    /* height: 400px;
    width: 400px; */
    overflow: hidden;
}

.community-img {
    width: 400px;
    height: auto;
    border-radius: 5px;
    margin: 15px 0px;
}

.four {
    background-color: theme("colors.sky.600");
    color: white;
    min-height: 500px;
}
.four-left {
    display: flex;
    align-items: center;
    max-width: 50%;
}

.four-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.four-right {
    outline: 0.5px solid white;
    height: max-content;
    padding: 10px 25px;
    border-radius: 5px;
}
.five {
    min-height: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tgob-pitch {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 80%;
    font-size: 18px;
    background-color: #e7e3dc;
    box-shadow: 10px 10px theme("colors.sky.800");
    padding: 25px 50px;
    width: max-content;
    border-radius: 5px;
}

.pitch-header {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 5px;
}


.tgob-script {
    margin: 25px 0px 15px 0px;
    font-weight: bold;
    font-size: 40px;
    font-family: "Alegreya";
    justify-content: center;
}


.tgob-link {
    color: #fbf9f6;
    padding: 10px 30px;
    border-radius: 15px;
    background-color: theme("colors.sky.600");
    outline: none;
}
.tgob-link:hover {
    outline: 5px dashed theme("colors.amber.500");
    background-color: #e7e3dc;
    color: theme("colors.amber.500");
    padding: 5px 30px;
    border-radius: 15px;
}


.community-cap {
    font-size: 14px;
    max-width: 400px;
}

.subheader {
    font-size: 20px;
    font-weight: bold;
}
.sub-block {
    margin: 20px 0px;
}

.footer {
    background-color: #424242;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.footer-items {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
}

.post-footer {
    height: 50px;
}

.footer-item {
    margin: 0px 20px;
}

@media only screen and (max-width: 800px) {
    .uno {
        flex-direction: column;
        margin-top: 15px;
    }

    /* .connect {
        display: none;
    } */

    .content {
        max-width: 100vw;
    }

    .uno-left {
        max-width: 100%;
    }
    .uno-right {
        display: flex;
        justify-content: center;
        max-width: 100%;
    }
    .navbuttons {
        margin: 0px;
        justify-content: end;
    }

    .navbar {
        margin: 0px;
        justify-content: space-between;
    }

    .logo {
        margin-left: 15px;
    }
    .definition-word {
        font-size: 24px;
    }

    .stonemasters {
        max-width: 60vw;
    }
    .connect {
        margin-right: 15px;
    }
    .community-grid {
        grid-template-columns: 1fr;
        outline: none;
    }
    .community-text{
        max-width: 300px;
        margin: 10px;
    }
    .community-item{
        max-width: 300px;
        margin: 10px;
    }

    .community-img {
        max-width: 100%;
    }

    .content-container {
        padding: 15px 30px;
    }

    .four {
        flex-direction: column;
    }
    .four-left {
        min-width: 100%;
        margin: 30px 0px
    }
    .four-right {
        outline: none;
        padding: 0px;
    }

    .sub-block {
        width: 85vw;
    }

    .pitch-header {
        font-size: 20px;
    }
    .tgob-pitch {
        font-size: 16px;
        padding: 15px 15px;
    }
    .tgob-script {
        font-size: 24px;
        padding: 5px;
        margin: 25px 0px 15px 0px;
    }
    .tgob-link {
        border-radius: 8px;
    }
    .five {
        min-height: 350px;
    }
    .stonemasters-wrap {
        margin-top: 25px;
    }
}
