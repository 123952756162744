body {
    margin: 0;
    font-family: "DM Mono";
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    background-color: theme("colors.stone.100");
    color: theme("colors.stone.800");
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    a {
        color: theme("colors.sky.600");
        text-decoration: none;
    }
    .card {
        border-radius: theme("borderRadius.lg");
        padding: theme("spacing.6");
        box-shadow: theme("boxShadow.xl");
    }

    .btn-std {
        cursor: pointer;
        background-color: theme("colors.amber.600");
        color: white;
        width: max-content;
        border-radius: theme("borderRadius.lg");
        font-size: theme("fontSize.sm");
        padding: theme("spacing.1") theme("spacing.2");
    }

    .btn-std:hover {
        background-color: theme("colors.stone.600");
    }

    .btn-primary {
        cursor: pointer;
        background-color: theme("colors.sky.600");
        color: white;
        width: max-content;
        font-size: theme("fontSize.sm");
        border-radius: theme("borderRadius.lg");
        padding: theme("spacing.1") theme("spacing.2");
    }

    .btn-primary:hover {
        background-color: theme("colors.stone.100");
        color: theme("colors.sky.600");
        outline: 0.5px solid theme("colors.sky.600");
    }

    .btn-ghost {
        color: theme("colors.sky.600");
        width: max-content;
        border-radius: theme("borderRadius.lg");
        padding: theme("spacing.1") theme("spacing.2");
        font-size: theme("fontSize.sm");
        cursor: pointer;
    }

    .btn-submit {
        cursor: pointer;
        background-color: theme("colors.amber.800");
        color: theme("colors.stone.100");
        width: max-content;
        border-radius: theme("borderRadius.lg");
        padding: theme("spacing.2") theme("spacing.4");
        font-size: theme("fontSize.sm");
    }

    .btn-ghost:hover {
        outline: 1px solid theme("colors.sky.600");
    }

    .btn-disabled {
        background-color: theme("colors.gray.300");
        color: white;
        cursor: not-allowed;
        width: max-content;
        border-radius: theme("borderRadius.lg");
        padding: theme("spacing.1") theme("spacing.2");
        font-size: theme("fontSize.sm");
    }

    .btn-disabled:hover {
        background-color: theme("colors.stone.400");
        color: white;
    }

    .ticker {
        padding: theme("spacing.1") theme("spacing.3");
        background-color: theme("colors.gray.300");
        border-radius: theme("borderRadius.lg");
        width: max-content;
    }
    .ticker-primary {
        padding: theme("spacing.1") theme("spacing.3");
        background-color: theme("colors.sky.800");
        color: white;
        border-radius: theme("borderRadius.lg");
        width: max-content;
    }

    .outline {
        padding: theme("spacing.2");
        outline: 1px solid theme("colors.stone.300");
        border-radius: theme("borderRadius.md");
    }

    .outline-nopad {
        outline: 1px solid theme("colors.gray.300");
        border-radius: theme("borderRadius.lg");
    }
    .outline-blue-dotted {
        padding: theme("spacing.2");
        outline: 3px dashed theme("colors.sky.600");
        border-radius: theme("borderRadius.lg");
    }

    .message-primary {
        background-color: #e7e3dc;
        padding: theme("spacing.1");
        border-radius: theme("borderRadius.lg");
    }

    .message-error {
        outline: 1px solid theme("colors.red.800");
        background-color: theme("colors.red.200");
        color: theme("colors.red.800");
        padding: theme("spacing.2");
        border-radius: theme("borderRadius.md");
    }

    .message-success {
        outline: 1px solid theme("colors.green.800");
        background-color: theme("colors.green.200");
        color: theme("colors.green.800");
        padding: theme("spacing.2");
        border-radius: theme("borderRadius.md");
    }

    .topbar {
        display: flex;
        margin: 0px 50px;
        min-height: 60px;
        align-items: center;
        justify-content: space-between;
    }
    .wallet {
        background-color: theme("colors.stone.300");
        padding: 0px theme("spacing.2");
        border-radius: theme("borderRadius.md");
    }
    .sidebar-link {
        cursor: pointer;
        padding: theme("spacing.3");
        border-radius: 0 theme("borderRadius.lg") theme("borderRadius.lg") 0;
        color: theme("colors.stone.500");
        display: flex;
        align-items: center;
        font-size: 14px;
    }

    .sidebar-link:hover {
        color: theme("colors.stone.200");
        background-color: theme("colors.sky.600");
        box-shadow: theme("boxShadow.md");
        font-weight: bold;
    }
    .script {
        font-family: "Alegreya";
    }
    .sans {
        font-family: "Inter";
    }

    .outline-box {
        padding: theme("spacing.8") theme("spacing.4");
        outline: 0.5px solid theme("colors.stone.600");
        box-shadow: 6px 6px 0 0 theme("colors.stone.600");
        border-radius: theme("borderRadius.md");
    }
    .outline-box:hover {
        box-shadow: 6px 6px 0 0 theme("colors.stone.600"),
            10px 10px 0 0 theme("colors.amber.500");
        border-radius: theme("borderRadius.md");
    }

    .sidebar-content {
        display: flex;
        max-width: 768px;
        margin-left: 50px;
        margin-top: 50px;
        justify-content: center;
    }

    .content-container {
        display: flex;
        justify-content: space-between;
        padding: 25px 50px;
    }

    textarea {
        border: none;
        overflow: auto;
        outline: none;

        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;

        resize: none;
    }

    h1 {
        font-size: theme("fontSize.2xl");
        font-weight: theme("fontWeight.bold");
    }
    h2 {
        font-size: theme("fontSize.xl");
        font-weight: theme("fontWeight.bold");
    }
    h3 {
        font-size: theme("fontSize.lg");
        font-weight: theme("fontWeight.bold");
    }

    @media only screen and (max-width: 800px) {
        .navbar {
            margin: 0px;
            justify-content: space-between;
        }
        .topbar {
            margin: 10px;
        }
        .sidebar-content {
            max-width: 100%;
            margin: 20px 50px;
        }
    }
    /* ... */
}