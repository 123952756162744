
.navbar {
    display: flex;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    flex-direction: row;
    margin: 0px 50px;
}

.logo {
    font-family: "Alegreya";
    font-weight: 1000;
    font-size: 28px;
    margin: 5px 0px;
    cursor: pointer;
}

.nostyle {
    text-decoration: none !important;
    color: #424242 !important;
}

.navbuttons{
    width: 100%;
    margin-left: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.auth-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.navlinks {
    display: flex;
    flex-direction: row;
}
.navlink:hover{
    opacity: 0.6;
}

.connect {
    cursor: pointer;
    outline: 0.5px solid #4c5770;
    height: max-content;
}

.auth-button {
    margin: 0px 5px;
    padding: 5px;
    border-radius: 5px;
    font-weight: 100;
}


.connect:a {
    color: black;
}
@media only screen and (max-width: 800px) {
    .uno {
        flex-direction: column;
    }

    .content {
        max-width: 100vw;
    }

    .uno-left {
        max-width: 100%;
    }
    .uno-right{
        max-width: 100%;
    }
    .navbuttons {
        margin: 0px;
        justify-content: end;
    }

    .navbar {
        margin: 0px;
        justify-content: space-between;
    }

    .logo {
        margin-left: 15px;
    }
    .definition-word {
        font-size: 24px;
    }

    .stonemasters {
        max-width: 60vw;
    }
    .connect {
        margin-right: 15px;
    }
}

@media only screen and (max-width: 648px) {
    .display-wallet {
        display: none;
    }
}