.team-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px;
    flex-grow: 1;
    flex-basis: auto;
}

.members {
    display: flex;
}

.member-img {
    max-width: 300px;
    height: auto;
}

.member-name {
    font-size: 20px;
    font-weight: bold;
}

.member-bio {
    margin: 20px 0px;
    width: 300px;
}

.member-link {
    margin-right: 20px;
}

.team-header {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 30px;
}
